



























































































import {
    Component,
    Vue
} from 'vue-property-decorator';

import DatePicker from '@/components/DatePicker.vue';
import DictionaryLinks from '@/components/DictionaryLinks.vue';

import axios from 'axios';
import Dictionary from '@/models/Dictionary';
import LearningCourseSchedule from '@/models/LearningCourseSchedule';
import LearningCourseScheduleResponse from '@/models/LearningCourseScheduleResponse';

import {
    WebSiteModule
} from '@/store';
import LearningCourse from '@/models/LearningCourse';

@Component({
    components: {
        DatePicker,
        DictionaryLinks
    },
})
export default class LearningCourseSchedules extends Vue {
    private url = 'LearningCourseSchedules';
    private urlLc = 'LearningCourses';
    private urlLt = 'LearningTypes';
    private urlLp = 'LearningProgramms';
    private dialog = false;
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    private items = new Array < LearningCourseScheduleResponse > ();
    private learningCourses = new Array<LearningCourse>();
    private learningTypes = new Array<Dictionary>();
    private learningProgramms = new Array<Dictionary>();
    private selectedLc: LearningCourse | null = null;
    private selectedLt: Dictionary | null = null;
    private selectedItem = new LearningCourseScheduleResponse();
    private selectedRow = 0;
    private get getTitle() {
        return this.selectedItem.Id === 0 ? 'Добавление' : 'Редактирование';
    }

    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);
            await this.load();
        });
    }
    private async load() {
        this.loading = true;
        try {
            const response = await axios.get(this.url);
            this.items = response.data;
            
            const responseLc = await axios.get(this.urlLc);
            this.learningCourses = responseLc.data;

            const responseLt = await axios.get(this.urlLt);
            this.learningTypes = responseLt.data;

             const responseLp = await axios.get(this.urlLp);
            this.learningProgramms = responseLp.data;

            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error;
            return;
        }
    }
    private addItem() {      
        this.selectedItem = new LearningCourseScheduleResponse();  
        this.dialog = true;
    }
    private editItem(row: LearningCourseScheduleResponse, index: number) {
        this.selectedRow = index;
        this.selectedItem = new LearningCourseScheduleResponse(row);
        this.selectedLc = this.learningCourses.filter((r)=>{ return r.Id === row.LearningCourseId; })[0];
        this.selectedLt = this.learningTypes.filter((r)=>{ return r.Id === row.LearningTypeId; })[0];
        this.dialog = true;
    }
    private async deleteItem(row: LearningCourseScheduleResponse, index: number) {
        this.selectedItem = row;
        this.loading = true;
        try {
            await axios.delete(this.url +'/'+this.selectedItem.Id);
            this.items.splice(index, 1);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private async saveItem() {
        if (!this.selectedLc) {
            this.error('Не выбран курс');
            return;
        }
        if (!this.selectedLt) {
            this.error('Не выбран тип обучения');
            return;
        }
        if (!this.selectedItem.DateFrom) {
            this.error('Не выбрана дата начала курса');
            return;
        }

        var row = new LearningCourseSchedule(this.selectedItem);
        row.LearningCourseId = this.selectedLc.Id;
        row.LearningTypeId = this.selectedLt.Id;

        this.loading = true;
        try {
            if (this.selectedItem.Id === 0) {
                // Добавление
                const response = await axios.post(this.url, row);
                this.items.push(response.data);
            } else {
                // Редактирование
                await axios.patch(this.url, row);
                const r = await axios.get(this.url+'/'+row.Id);
                // Обновляем в таблице
                Vue.set(this.items, this.selectedRow, r.data);
            }
            this.loading = false;
            this.dialog = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private formattedDate(date: Date | string | null) {
        if (!date || date === '') {
            return '';
        }

        const d = new Date(date);

        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return day + '.' + month + '.' + year;
    }
    private getCourse(item: LearningCourse): string {
        let text = item.Name;
        for (let i = 0; i < this.learningProgramms.length; i++) {
            const e = this.learningProgramms[i];
            if (e.Id === item.LearningProgrammId) {
                text += ' (' + e.Name + ')';
                break;
            }
        }
        return text;
    }
}
