


































































import {
    Component,
    Vue
} from 'vue-property-decorator';

import DictionaryLinks from '@/components/DictionaryLinks.vue';

import axios from 'axios';
import MenuItem from '@/models/MenuItem';
import {
    WebSiteModule
} from '@/store';

@Component({
    components: {
        DictionaryLinks
    },
})
export default class MailMessages extends Vue {
    private uri = 'MailMessages';
    private dialog = false;
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    private items = new Array < any > ();
    private selectedItem: any = {};

    public mounted(): void {
        this.$nextTick(async () => {
            await this.load();
        });
    }
    private async load() {
        this.loading = true;
        try {
            const response = await axios.get(this.uri);
            this.items = response.data;
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error;
            return;
        }
    }
    private formattedDate(d: Date) {
        if (!d) {
            return '';
        }
        const date = new Date(d);
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        const year = String(date.getFullYear());

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return day + '.' + month + '.' + year;
    }
    private openItem(row: MenuItem) {
        this.selectedItem = row;
        this.dialog = true;
    }
    private async deleteItem(row: MenuItem, index: number) {
        this.selectedItem = row;
        this.loading = true;
        try {
            await axios.delete(this.uri +'/'+this.selectedItem.Id);
            this.items.splice(index, 1);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private async read() {
        this.loading = true;
        try {
            await axios.patch(this.uri, {
                Id: this.selectedItem.Id,
                Processed: this.selectedItem.Processed
            });            
            this.loading = false;
            this.dialog = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
}
