











































































































































































import {
    Component,
    Vue,
} from 'vue-property-decorator';

import LoadingPannel from '@/components/LoadingPannel.vue';
import DialogWindow from '@/components/DialogWindow.vue';
import ContactUs from '@/components/ContactUs.vue';

import {
    WebSiteModule
} from '@/store';
import Menu from './models/Menu';

@Component({
    components: {
        LoadingPannel,
        DialogWindow,
        ContactUs
    }
})
export default class App extends Vue {
    private dialogFeedback = false;
    private fab = false;
    private emailDialog = false;
    private phoneDialog = false;
    private get overlay() {
        return WebSiteModule.state.Initializing;
    }
    private get mainMenuItems() {
        return WebSiteModule.state.MenuItems;
    }
    private extraPermissions = false;
    private get isAuthenticated() {
        this.extraPermissions = WebSiteModule.state.Identity.Roles.some(q => q === 'Admin' || q === 'ContentManager');
        return WebSiteModule.state.Identity.IsAuthenticated;
    }
    private get UserName() {
        return WebSiteModule.state.Identity.Name;
    }
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private get dialog() {
        return WebSiteModule.state.Dialog;
    }
    private set dialog(dialog: boolean) {
        WebSiteModule.mutations.setDialog(dialog);
    }
    private get error() {
        return WebSiteModule.state.Error;
    }
    private set error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    private navigate(row: Menu) {
        if (this.$route.fullPath !== row.FullPath) {
            this.$router.push(row.FullPath);
        }
    }
    private activeClass(row: Menu) {
        if (this.$route.fullPath.startsWith(row.FullPath)) {
            return 'router-link-exact-active';
        }
        return '';
    }
    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);

            await WebSiteModule.actions.loadAccount();
            let redirect = false;
            if (WebSiteModule.state.ReturnUrl.length > 2) {
                if (this.$route.fullPath !== WebSiteModule.state.ReturnUrl) {
                    redirect = true;
                    setTimeout(() => {
                        const redirect = WebSiteModule.state.ReturnUrl;
                        this.$router.push(redirect);
                        WebSiteModule.mutations.setReturnUrl('');
                    }, 250);
                }
            }
            if (redirect) {
                setTimeout(() => {
                    WebSiteModule.mutations.setInitializing(false);
                }, 250);
            } else {
                WebSiteModule.mutations.setInitializing(false);
            }
        });
    }
    private login() {
        //
    }
    private logout() {
        //
    }
}
