export default class MenuItem
{
    public Id = 0;
    public Name = '';
    public Link = '';
    public FullPath = '';
    public Visible = true;
    public SortOrder = 0;
    public ParentId: number | null = null;
    public Parent: MenuItem | null = null;
    public ChildMenuItems = new Array<MenuItem>();

    constructor(row: MenuItem | null = null) {
        if (!row) {
            return;
        }

        this.Id = row.Id;
        this.Name = row.Name;
        this.Link = row.Link;
        this.FullPath = row.FullPath;
        this.Visible = row.Visible;
        this.ParentId = row.ParentId;
        this.SortOrder = row.SortOrder;

        if (row.Parent) {
            this.Parent = new MenuItem(row.Parent);
        }
        if (row.ChildMenuItems && row.ChildMenuItems.length > 0) {
            for (let i = 0; i < row.ChildMenuItems.length; i++) {
                const element = row.ChildMenuItems[i];
                this.ChildMenuItems.push(new MenuItem(element));
            }
        }
    }
}