import Dictionary from './Dictionary';

export default class LearningCourse {
    public Id = 0;
    public Name = '';
    public Text = '';
    public DateFrom = new Date();
    public DateTill: Date | null = null;
    public Visible = false;
    public Link = '';
    public LearningProgrammId = 0;
    public LearningProgramm: Dictionary | null = new Dictionary();

    constructor(row : LearningCourse | null = null) {
        if (!row) {
            return;
        }
        this.Id = row.Id;
        this.Name = row.Name;
        this.Text = row.Text;
        this.DateFrom = new Date(row.DateFrom);
        if (row.DateTill) {
            this.DateTill = new Date(row.DateTill);
        }
        this.Visible = row.Visible;
        this.Link = row.Link;
        this.LearningProgrammId = row.LearningProgrammId;
        if (row.LearningProgramm) {
            this.LearningProgramm = new Dictionary(row.LearningProgramm);
        }
    }
}