import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueYandexMetrika from 'vue-yandex-metrika';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import '../src/assets/css/site.css';
import '@fortawesome/fontawesome-free/js/all.js';
//import 'bootstrap';

import axios from 'axios';
//axios.defaults.baseURL = '/Rn-Uchet/api/';
axios.defaults.baseURL = '/api/';
axios.defaults.maxContentLength = 1024 ** 3;
axios.defaults.withCredentials = true;

Vue.config.productionTip = false;


Vue.use(VueYandexMetrika, {
  id: 80411977,
  router: router,
  env: 'production'//process.env.NODE_ENV
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
