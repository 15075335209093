import { Actions } from 'vuex-smart-module';
import WebSiteGetters from './getters';
import WebSiteMutations from './mutations';
import WebSiteState from './state';
import axios from 'axios';

export default class WebSiteActions extends Actions<WebSiteState, WebSiteGetters, WebSiteMutations, WebSiteActions> {
    loadMenuItems():Promise<void> {
        return axios.get('/api/Menu').then((res) => {
            this.commit('setMenuItems', res.data);
        });
    }
    loadAccount():Promise<void> {
        return axios.get('Account').then((res) => {
            this.commit('login', res.data);
        });
    }
    navigate(link: string):Promise<void> {
        const fd = new FormData();
        fd.append('link',link);
        return axios.post('Navigate', fd);
    }
}
