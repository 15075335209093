import Article from '@/models/Article';

export default class HtmlMarkup {
    public static getMarkup(content: string, files: File[]): string {
        let html = content;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = this.removeSpaces(file.name);
            //console.log('Process file: ' + fileName);
            const fileType = file.type;

            if (this.isPicture(fileType)) {
                // обработка картинок
                html = this.processPicture(fileName, html, '#', i);
            } else if (this.isVideo(fileType)) {
                // Обработка видеофайлов
                html = this.processVideo(fileName, html, '#');
            } else {
                // прочие файлы и ссылки
                html = this.processSimpleHref(fileName, file.name, html, '#');
            }
        }

        // Убираем лишние параграфы
        while (html.indexOf('<p><br></p>') >= 0) {
            html = html.replace('<p><br></p>', '');
        }

        return html;
    }
    public static getMarkupArticle(a: Article, baseUrl: string | undefined): string {
        let html = a.Text;

        for (let i = 0; i < a.ArticleFiles.length; i++) {
            const file = a.ArticleFiles[i];
            const fileName = this.removeSpaces(file.FileName);
            if (baseUrl) {
                baseUrl = baseUrl.replace('/api','');
            } else {
                baseUrl = '/';
            }

            if (this.isPicture(file.FileType)) {
                // обработка картинок
                html = this.processPicture(fileName, html, baseUrl + file.Link, i);
            } else if (this.isVideo(file.FileType)) {
                // Обработка видеофайлов
                html = this.processVideo(fileName, html, baseUrl + file.Link);
            } else {
                // прочие файлы и ссылки
                html = this.processSimpleHref(fileName, file.FileName, html, baseUrl + file.Link);
            }
        }   
        return html;
    }
    private static isVideo(fileType: string): boolean {
        return fileType.indexOf('webm') >= 0 || fileType.indexOf('mp4') >= 0;
    }
    private static isPicture(fileType: string): boolean {
        return fileType.indexOf('jpeg') >= 0 || fileType.indexOf('jpg') >= 0 || fileType.indexOf('png') >= 0 || fileType.indexOf('bmp') >= 0;
    }
    private static processPicture2(fileName: string, html: string, link: string, index: number): string {
        let img = '';
        if (link === '#') {
            if (this.isEven(index)) {
                img = '<img src="' + link + '" alt="Тут будет картинка после сохранения" align="left" width="300px" height="200px" class="article-image-left">';
            } else {
                img = '<img src="' + link + '" alt="Тут будет картинка после сохранения" align="right" width="300px" height="200px" class="article-image-right">';
            }
        } else {
            if (this.isEven(index)) {
                img = '<img src="' + link + '" alt="" align="left" class="article-image-left">';
            } else {
                img = '<img src="' + link + '" alt="" align="right" class="article-image-right">';
            }
        }
        
        // Меняем все картинки
        html = this.replaceFileName(fileName, img, html);
        return html;
    }
    private static processPicture(fileName: string, html: string, link: string, index: number): string {
        let img = '';
        if (link === '#') {
            if (this.isEven(index)) {
                img = '<img src="' + link + '" alt="Тут будет картинка после сохранения" width="300px" height="200px">';
            } else {
                img = '<img src="' + link + '" alt="Тут будет картинка после сохранения" width="300px" height="200px">';
            }
        } else {
            if (this.isEven(index)) {
                img = '<img src="' + link + '" alt="">';
            } else {
                img = '<img src="' + link + '" alt="">';
            }
        }
        
        // Меняем все картинки
        html = this.replaceFileName(fileName, img, html);
        return html;
    }
    private static processVideo(fileName: string, html: string, link: string): string {
        const video1 = '<video id=\'my-video\' class=\'video-js my-video\' controls preload=\'auto\' width=\'722\' height=\'480\' data-setup=\'{}\'>';

        let video2 = '';
        if (fileName.indexOf('mp4') >= 0) {
            video2 = '<source src="' + link + '" type="video/mp4"></video>';
        } else {
            video2 = '<source src="' + link + '" type="video/webm"></video>';
        }

        // Меняем все видео
        html = this.replaceFileName(fileName, video1 + video2, html);
        return html;
    }
    private static processSimpleHref(fileName: string, originalFileName: string, html: string, link: string): string {        
        const tag = '<a class="bg-warning" href="' + link + '" alt="">' + originalFileName + '</a>';
        // Меняем все картинки
        html = this.replaceFileName(fileName, tag, html);
        return html;
    }
    public static removeSpaces(fileName: string): string {
        // Имя файла без пробелов
        const fileName2 = fileName.replaceAll(/\s/g, '');
        return fileName2;
    }
    public static getFileNameTag(fileName: string): string {
        return '<!--' + fileName + '-->';
    }
    public static getFileNameHtmlTag(fileName: string): string {
        return '&lt;!--' + fileName + '--&gt;';
    }
    public static replaceFileName(fileName: string, replaceWithTag: string, html: string): string {
        // Меняем все картинки
        const exp = this.getFileNameTag(fileName);
        //console.log('Replace "'+exp+'" with "'+replaceWithTag+'" in "'+html+'"');
        while (html.indexOf(exp) >= 0) {
            html = html.replace(exp, replaceWithTag);
        }
        return html;
    }
    public static replaceFileNameWithTag(fileName: string, replaceWithTag: string, html: string): string {
        // Меняем все картинки
        const exp = this.getFileNameHtmlTag(fileName);
        //console.log('Replace "'+exp+'" with "'+replaceWithTag+'" in "'+html+'"');
        while (html.indexOf(exp) >= 0) {
            html = html.replace(exp, replaceWithTag);
        }
        return html;
    }
    public static removeTag(fileName: string, html: string): string {
        // Удаляем ссылки
        const exp = this.getFileNameHtmlTag(fileName);
        while (html.indexOf(exp) >= 0) {
            html = html.replace(exp, '');
        }
        return html;
    }
    public static removeFileName(fileName: string, html: string): string {
        // Удаляем ссылки
        let exp = this.getFileNameTag(fileName);
        exp = this.removeSpaces(exp);
        while (html.indexOf(exp) >= 0) {
            html = html.replace(exp, '');
        }
        return html;
    }
    private static isEven(n: number): boolean {
        return n % 2 === 0;
    }
}
