
































































































































import {
    Component,
    Ref,
    Vue
} from 'vue-property-decorator';

import $ from 'jquery';
import axios from 'axios';
import Quill from 'quill';
import Delta from 'quill-delta';
import {
    QuillDeltaToHtmlConverter
} from 'quill-delta-to-html';

import ModalForm from '@/components/ModalForm.vue';
import Article from '@/models/Article';
import HtmlMarkup from '@/models/HtmlMarkup';
import {
    WebSiteModule
} from '@/store';

@Component({
    components: {
        ModalForm,
    },
})
export default class ArticleAdd extends Vue {
    private uri = 'Article';
    private id = 0;
    private htmlVisible = false;
    //private files = new Array < File > ();
    private articleId = 0;
    private article = new Article();
    private quill!: Quill;
    @Ref('modalForm')
    private readonly modalForm!: ModalForm;
    private get subject() {
        return WebSiteModule.state.Subject;
    }
    private set subject(v: string) {
        WebSiteModule.mutations.setSubject(v);
    }
    private get visible() {
        return WebSiteModule.state.Visible;
    }
    private set visible(v: boolean) {
        WebSiteModule.mutations.setVisible(v);
    }
    private get isContinue() {
        return WebSiteModule.state.Continue;
    }
    private set isContinue(v: boolean) {
        WebSiteModule.mutations.setContinue(v);
    }
    private get html() {
        return WebSiteModule.state.Html;
    }
    private set html(v: string) {
        WebSiteModule.mutations.setHtml(v);
    }
    private get files() {
        return WebSiteModule.state.Files;
    }
    private set files(v: File[]) {
        WebSiteModule.mutations.setFiles(v);
    }

    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);

            this.id = Number(this.$route.query.MenuId);
            const html = WebSiteModule.state.Html;
            // Add fonts to whitelist
            let Font = Quill.import('formats/font');
            // We do not add Sans Serif since it is the default
            Font.whitelist = ['arial', 'courier', 'exo', 'europe', 'georgia', 'impact', 'roboto', 'serif', 'sergoe', 'tahoma', 'times', 'verdana'];
            Quill.register(Font, true);
            /*
            var Size = Quill.import('attributors/style/size');        
            Size.whitelist = ['10px', '18px', '24px', '32px'];
            Quill.register(Size, true);
            */
            this.quill = new Quill('#editor', {
                modules: {
                    toolbar: '#toolbar-container',
                },
                theme: 'snow'
            });
            // Отключаем вставку картинки из буфера обмена
            this.quill.clipboard.addMatcher('img', () => {
                return new Delta();
            });
            this.quill.on('text-change', (delta, oldDelta, source) => {
                if (source == 'user') {
                    this.rebuildPreview();
                }
            });
            if (this.isContinue) {
                this.html = html;
                this.isContinue = false;
            } else {
                this.html = '';
            }
        });
    }
    private chooseFile() {
        $('#fileUpload').trigger('click');
    }
    private addFile(e: any) {
        if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
                const file: File = e.target.files[i];
                if (file) {
                    //this.files.push(file);
                    WebSiteModule.mutations.appendFile(file);
                }
            }
        }
    }
    private deleteFile(index: number) {
        const fileName = this.files[index];

        let html = this.quill.root.innerHTML;

        // Удаляем теги файлы из статьи
        html = HtmlMarkup.removeTag(fileName.name, html);
        this.quill.root.innerHTML = html;

        // Удаляем файл
        //this.files.splice(index, 1);
        WebSiteModule.mutations.deleteFile(index);
        this.html = HtmlMarkup.getMarkup(html, this.files);
    }
    private insertFile(file: File) {
        // Имя файла без пробелов
        const fileName = HtmlMarkup.getFileNameTag(HtmlMarkup.removeSpaces(file.name));
        //console.log('FileName: ' + fileName);
        const selection = this.quill.getSelection(true);
        this.quill.insertText(selection.index, fileName);
        // обновить просмотр
        this.rebuildPreview();
    }
    private fileSize(file: File) {
        return Math.round(file.size / 1024);
    }
    private isPicture(file: File): boolean {
        const fileType = file.type;
        return fileType.indexOf('jpeg') >= 0 || fileType.indexOf('jpg') >= 0 || fileType.indexOf('png') >= 0 || fileType.indexOf('bmp') >= 0;
    }
    private async save() {
        const data = new FormData();
        data.append('MenuId', String(this.id));
        data.append('Subject', this.article.Subject);
        data.append('Text', this.quill.root.innerHTML);
        data.append('Visible', String(this.article.Visible));
        for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i];
            data.append('Files', file, file.name);
        }
        try {
            await axios.post(this.uri, data);
        } catch (error) {
            this.modalForm.onErrorResponse(error);
        }
    }
    private rebuildPreview() {
        const html = this.getHTML();
        this.html = HtmlMarkup.getMarkup(html, this.files);
    }
    private gotoHtmlAdd() {
        this.isContinue = true;
        this.$router.push('/ru/ArticleHtmlAdd?MenuId=' + this.id);
    }
    private getHTML() {
        const contents = this.quill.getContents().ops;
        const converter = new QuillDeltaToHtmlConverter(contents, {
            encodeHtml: true,
            inlineStyles: true,
        });
        return converter.convert();
    }
    private setHTML(html: string) {
        //this.quill.pasteHTML(html);
        this.quill.clipboard.dangerouslyPasteHTML(html);
    }
    private togglePreview() {
        if (!this.htmlVisible) {
            this.rebuildPreview();
        }
        this.htmlVisible = !this.htmlVisible;
    }
}
