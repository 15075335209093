











import {
    Component,
    Vue
} from 'vue-property-decorator';

import axios from 'axios';

import {
    WebSiteModule
} from '@/store';

@Component({
    components: {},
})
export default class Logout extends Vue {
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);

            if (!WebSiteModule.state.Identity.IsAuthenticated) {
                this.error('Вход в систему еще не был осуществлен');
            }
        });
    }
    private async logout() {
        this.loading = true;
        try {
            await axios.post('Account/Logout');
            WebSiteModule.mutations.logout();
            this.loading = false;
            setTimeout(() => {
                this.$router.push('/');
            }, 250);
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
        this.loading = false;
    }
}
