import Vue from 'vue';
import Vuex from 'vuex';
import { createStore, Module } from 'vuex-smart-module';
import module from './modules/index';

Vue.use(Vuex);

const root = new Module({
  modules: {
      module,
  }
});

// The 1st argument is root module.
// Vuex store options should be passed to the 2nd argument.
const store = createStore(
  root,  
  {
    strict: process.env.NODE_ENV !== 'production'
  }
);

export default store;
export const WebSiteModule = module.context(store);

/*
export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
});
*/