



















































































import {
    Component,
    Vue
} from 'vue-property-decorator';

import DictionaryLinks from '@/components/DictionaryLinks.vue';

import axios from 'axios';
import MenuItem from '@/models/MenuItem';
import {
    WebSiteModule
} from '@/store';

@Component({
    components: {
        DictionaryLinks
    },
})
export default class ManageContent extends Vue {
    private dialog = false;
    private formTitle = 'Добавление';
    private headers = [{
            text: 'Название',
            value: 'Name'
        },
        {
            text: 'Родитель',
            value: 'ParentId'
        },
        {
            text: 'Путь',
            value: 'Link'
        },
        {
            text: 'Видимость',
            value: 'Visible'
        },
    ];
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    private menuItems = new Array < MenuItem > ();
    private selectedMenuItem = new MenuItem();
    private selectedParent: MenuItem | null = null;
    private selectedRow = 0;
    private get getTitle() {
        return this.selectedMenuItem.Id === 0 ? 'Добавление элемента меню' : 'Редактирование элемента меню';
    }

    public mounted(): void {
        this.$nextTick(async () => {
            await this.load();
        });
    }
    private async load() {
        this.loading = true;
        try {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);

            const response = await axios.get('MenuItems');
            this.menuItems = response.data;
            this.loading = false;
        } catch (error) {
            this.loading = false;
            //this.modalForm.onErrorResponse(error);
            return;
        }
    }
    private addItem() {      
        this.selectedMenuItem = new MenuItem();  
        this.dialog = true;
    }
    private editItem(row: MenuItem, index: number) {
        this.selectedRow = index;
        this.selectedMenuItem = new MenuItem(row);
        if (row.ParentId) {
            this.selectedParent = this.menuItems.filter((r)=>r.Id === row.ParentId)[0];
        }
        
        this.dialog = true;
    }
    private async deleteItem(row: MenuItem, index: number) {
        this.selectedMenuItem = row;
        this.loading = true;
        try {
            await axios.delete('MenuItems/'+this.selectedMenuItem.Id);
            this.menuItems.splice(index, 1);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private openItem(row: MenuItem) {
        this.$router.push(row.FullPath);
    }
    private async saveItem() {
        if (this.selectedMenuItem.Name.length < 3) {
            this.error('Не заполнено название');
            return;
        }
        if (this.selectedMenuItem.Link.length < 2) {
            this.error('Не заполнена ссылка');
            return;
        }
        if (this.selectedMenuItem.Link.indexOf('/') >=0) {
            this.error('Ссылка не должна содержать слэш');
            return;
        }
        /*
        if (!this.selectedMenuItem.ParentId) {
            this.error('Не выбран родитель');
            return;
        }
        */
        if (!this.selectedParent) {
            this.error('Не выбран родитель');
            return;
        }

        var row = new MenuItem(this.selectedMenuItem);
        row.ParentId = this.selectedParent.Id;
        row.Parent = null;
        
        this.loading = true;
        try {
            if (this.selectedMenuItem.Id === 0) {
                // Добавление
                const response = await axios.post('MenuItems', row);
                this.menuItems.push(response.data);
            } else {
                // Редактирование
                await axios.patch('MenuItems', row);
                // Обновляем в таблице
                row.Parent = this.selectedParent;
                Vue.set(this.menuItems, this.selectedRow, row);
            }
            this.loading = false;
            this.dialog = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private getParentName(menu: MenuItem) {
        if (!menu.Parent) {
            return '';
        }
        return menu.Parent.Name;
    }
}
