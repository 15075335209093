











































import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

import axios from 'axios';
import {
    WebSiteModule
} from '@/store';

@Component({})
export default class ContactUs extends Vue {
    private name = '';
    private nameRules = [
        (v: string) => !!v || 'Необходимо заполнить',
        (v: string) => (v && v.length <= 50) || 'Не более 50 символов',
    ]
    private telephone = '';
    private telephoneRules = [
        (v: string) => !!v || 'Необходимо заполнить',
        (v: string) => (v && v.length >= 10) || 'Не менее 10 символов',
    ]
    private email = '';
    private emailRules = [
        (v: string) => !!v || 'Необходимо заполнить',
        (v: string) => /.+@.+\..+/.test(v) || 'Неверный формат эл.почты',
    ]
    private text = '';
    private textRules = [
        (v: string) => !!v || 'Необходимо заполнить',
        (v: string) => (v && v.length <= 1000) || 'Не более 1000 символов',
    ]
    private agreed = false;
    private get valid() {
        return this.name && this.name.length>1 && this.email && this.email.length > 10  && this.telephone && this.telephone.length >5 && this.agreed && this.text.length < 1000;
    }
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }

    @Prop({
        type: Boolean,
        required: true,
        default: false
    })
    private dialog!: boolean;
    private get _dialog() {
        return this.dialog;
    }
    private set _dialog(modal: boolean) {
        this.$emit('update:dialog', modal);
    }
    private async sendMail() {
        if (!this.valid) {
            return;
        }
        this.loading = true;
        try {
            const data = {
                Name: this.name,
                Telephone: this.telephone,
                Email: this.email,
                Text: this.text
            };
            await axios.post('MailMessages', data);
            this.loading = false;
            this.error('Спасибо за сообщение, мы скоро ответим вам.');
            this._dialog = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
        }
    }
}
