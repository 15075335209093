
















import {
    Component,
    Vue
} from 'vue-property-decorator';

import axios from 'axios';

import {
    WebSiteModule
} from '@/store';

@Component({
    components: {},
})
export default class Login extends Vue {
    private valid = false;
    private login = '';
    private password = '';
    private confirmPassword = '';
    private show1 = false;
    private show2 = false;
    private rememberMe = false;
    private rules = {
        required: (value: string) => !!value || 'Необходимо указать.',
        min: (v: string) => v.length >= 8 || 'Не менее 8 символов',
    };
    private emailRules = [
        (v: string) => !!v || 'Необходимо указать эл.почту',
        (v: string) => /.+@.+/.test(v) || 'Неверный формат эл.почты',
    ]

    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);

            if (WebSiteModule.state.Identity.IsAuthenticated) {
                this.error('Вход в систему уже был осуществлен ранее');
            }
        });
    }
    private async handleSubmit(e: any) {
        e.preventDefault();
        if (!this.valid) {
            return;
        }
        if (this.password.length < 6) {
            return;
        }
        if (this.confirmPassword.length < 6) {
            return;
        }
        this.loading = true;
        try {
            await axios.post('Account/Register', {
                Email: this.login,
                Password: this.password,
                ConfirmPassword: this.confirmPassword
            });
            setTimeout(() => {
                this.$router.push('/');
            }, 250);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
}
