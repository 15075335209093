
























































import {
    Component,
    Watch,
    Vue
} from 'vue-property-decorator';

import {
    WebSiteModule
} from '@/store';
import Menu from '@/models/Menu';
//import MenuItem from '@/models/MenuItem';
//import MenuItemExt from '@/models/MenuItemExt';

@Component({
    components: {

    },
})
export default class Article extends Vue {
    private menuItems = new Array < Menu > ();
    private get menuItemsExist() {
        return this.menuItems.length > 0;
    }
    private get allMenuItems() {
        return WebSiteModule.state.MenuItems;
    }
    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);

            // Если меню уже загружено, то строим его
            if (WebSiteModule.state.MenuItems && WebSiteModule.state.MenuItems.length > 0) {
                this.buildMenuItems();
            }
        });
    }
    private buildMenuItems() {
        // const origin = this.$route.meta.origin;
        const id = Number(this.$route.meta.id);
        // const parentId = Number(this.$route.meta.articleId);
        // console.log('Rebuild menu with origin:' + origin +', and Id='+id+', and ParentId=' + parentId);

        this.menuItems = new Array < Menu > ();
        for (let i = 0; i < WebSiteModule.state.PlainMenuItems.length; i++) {
            const mi = WebSiteModule.state.PlainMenuItems[i];
            if (mi.ParentId === id) {  
                this.menuItems.push(mi);
                /*
                mi.SubMenu.forEach(si => {
                    this.menuItems.push(si);
                });
                */
            }
        }
        /*
        const menuItems = this.allMenuItems.filter((query) => {
            return query.ParentId === parentId;
        });
        this.menuItems = new Array < MenuItemExt > ();
        menuItems.forEach((item) => {
            var menuItem = new MenuItemExt(item);
            menuItem.FullPath = origin + '/' + menuItem.Link;
            menuItem.ChildMenuItems = new Array < MenuItemExt > ();

            const subMenuItems = this.allMenuItems.filter((sm) => {
                return sm.ParentId === menuItem.Id;
            });

            if (subMenuItems && subMenuItems.length > 0) {
                subMenuItems.forEach(smi => {
                    const mi = new MenuItemExt(smi);
                    menuItem.FullPath = origin + '/' + menuItem.Link + '/' + mi.Link;
                    menuItem.ChildMenuItems.push(mi);
                });
            }

            this.menuItems.push(menuItem);
        });
        if (this.menuItems.length > 0) {
            if (this.menuItems[0].ChildMenuItems.length > 0) {
                const path = this.menuItems[0].ChildMenuItems[0].FullPath;
                //console.log('CurPath=' + this.$route.path + ', origin=' + this.$route.meta.origin + ', path=' + path);
                if (this.$route.path === this.$route.meta.origin && this.$route.path !== path) {
                    this.$router.push(path);
                }
            } else {
                const path = this.menuItems[0].FullPath;
                //console.log('CurPath=' + this.$route.path + ', origin=' + this.$route.meta.origin + ', path=' + path);
                if (this.$route.path === this.$route.meta.origin && this.$route.path !== path) {
                    this.$router.push(path);
                }
            }
        }
        */
    }
    private itemClick(item: Menu) {
        if (item.FullPath) {
            if (this.$route.fullPath !== item.FullPath) {
                this.$router.push(item.FullPath);
            }
        }
    }
    @Watch('menuItems')
    public menuItemsChanges(): void {
        if (!this.allMenuItems || this.allMenuItems.length === 0) {
            return;
        }
        //this.buildMenuItems();
    }
    private onChange(e: any) {
        //console.log(e);
    }

}
