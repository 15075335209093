export default class FileBlob {
    public Id = 0;
    public Guid = '';
    public FileBytes = '';
    public DateAdd = new Date();
    public UserAdd = '';

    constructor(row: FileBlob | null = null) {
        if (!row) {
            return;
        }
        this.Id = row.Id;
        this.Guid = row.Guid;
        this.FileBytes = row.FileBytes;
        this.DateAdd = row.DateAdd;
        this.UserAdd = row.UserAdd;
    }
}