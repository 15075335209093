export default class Identity {
    public AuthenticationType = '';
    public Name = '';
    public Email = '';
    public IsAuthenticated = false;
    public Roles = new Array<string>();

    constructor(row: Identity | null = null) {
        if (!row) {
            return;
        }
        this.AuthenticationType = row.AuthenticationType;
        this.Name = row.Name;
        this.Email = row.Email;
        this.IsAuthenticated = row.IsAuthenticated;
        this.Roles = row.Roles;
    }
}