import ArticleFile from '@/models/ArticleFile';

export default class Article {
    public Id = 0;
    public MenuItemId = 0;
    public Subject = '';
    public Text = '';
    public Visible = false;
    public DateAdd = new Date();
    public UserAdd = '';
    public ArticleFiles = new Array<ArticleFile>();

    constructor(row: Article | null = null) {
        if (!row){
            return;
        }
        this.Id = row.Id;
        this.MenuItemId = row.MenuItemId;
        this.Subject = row.Subject;
        this.Text = row.Text;
        this.Visible = row.Visible;
        this.DateAdd = row.DateAdd;
        this.UserAdd = row.UserAdd;

        if (row.ArticleFiles && row.ArticleFiles.length > 0) {
            row.ArticleFiles.forEach((r)=> {
                this.ArticleFiles.push(new ArticleFile(r));
            });
        }
    }
}