import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { WebSiteModule } from '@/store';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Logout from '../views/Logout.vue';
import ManageContent from '../views/ManageContent.vue';
//==========================================================
import Redirect from '../views/Redirect.vue';
import AccessDenied from '../views/AccessDenied.vue';
import NotFound from '../views/NotFound.vue';
import ContactUs from '../views/ContactUs.vue';
import MailMessages from '../views/MailMessages.vue';
import LearningRequests from '../views/LearningRequests.vue';
//==========================================================
//import LearningProgramms from '../views/dictionaries/LearningProgramms.vue';
import LearningTypes from '../views/dictionaries/LearningTypes.vue';
import LearningCourses from '../views/dictionaries/LearningCourses.vue';
import LearningCourseSchedules from '../views/dictionaries/LearningCourseSchedules.vue';
//==========================================================
import LearningProgrammsView from '../views/learning/LearningProgramms.vue';
import LearningCourseRead from '../views/learning/LearningCourseRead.vue';
//==========================================================
import Article from '@/views/Article.vue';
import ArticleAdd from '@/views/ArticleAdd.vue';
import ArticleHtmlAdd from '@/views/ArticleHtmlAdd.vue';
import ArticleEdit from '@/views/ArticleEdit.vue';
import ArticleHtmlEdit from '@/views/ArticleHtmlEdit.vue';
import ArticleContent from '@/views/ArticleContent.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Account/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Account/Logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/Account/Register',
    name: 'Register',
    component: Register
  },
  {
    path: '/ru/AccessDenied',
    name: 'AccessDenied',
    component: AccessDenied
  },
  {
    path: '/ru/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/Redirect/:id',
    name: 'Redirect',
    component: Redirect
  },
  {
    path: '/ru/ManageContent',
    name: 'ManageContent',
    component: ManageContent,
    meta: {
      RequireAuth: true,
      ContentManager: true
    }
  },
  {
    path: '/ru/MailMessages',
    name: 'MailMessages',
    component: MailMessages,
    meta: {
      RequireAuth: true,
      ContentManager: true
    }
  },
  {
    path: '/ru/LearningRequests',
    name: 'LearningRequests',
    component: LearningRequests,
    meta: {
      RequireAuth: true,
      ContentManager: true
    }
  },
  //==============================================================================
  {
    path: '/Dictionary/LearningTypes',
    name: 'LearningTypes',
    component: LearningTypes,
    meta: {
      RequireAuth: true,
      ContentManager: true
    }
  },
  /*
  {
    path: '/Dictionary/LearningProgramms',
    name: 'LearningProgramms',
    component: LearningProgramms,
    meta: {
      RequireAuth: true,
      ContentManager: true
    }
  },
  */
  {
    path: '/Dictionary/LearningCourses',
    name: 'LearningCourses',
    component: LearningCourses,
    meta: {
      RequireAuth: true,
      ContentManager: true
    }
  },
  {
    path: '/Dictionary/LearningCourseSchedules',
    name: 'LearningCourseSchedules',
    component: LearningCourseSchedules,
    meta: {
      RequireAuth: true,
      ContentManager: true
    }
  },
  //==============================================================================
  /*{
    path: '/Education/Courses',
    name: 'LearningProgrammsView',
    component: LearningProgrammsView,
    meta: {
      origin: '/Services', id: 37, parentId: 37
    }
  },  
  {
    path: '/Education/Course',
    name: 'LearningCourseRead',
    component: LearningCourseRead,
  },
  */
  //==============================================================================
  {
    path: '/ru/ArticleAdd',
    name: 'ArticleAdd',
    component: ArticleAdd
  },
  {
    path: '/ru/ArticleHtmlAdd',
    name: 'ArticleHtmlAdd',
    component: ArticleHtmlAdd
  },
  {
    path: '/ru/ArticleEdit',
    name: 'ArticleEdit',
    component: ArticleEdit
  },
  {
    path: '/ru/ArticleHtmlEdit',
    name: 'ArticleHtmlEdit',
    component: ArticleHtmlEdit
  },
  /*
  {
    path: '/about',
    component: About,
    children: [{
      path: 'Us',
      components: {
        default: About,
        city: Us
      }
    }, {
      path: 'Moscow',
      components: {
        default: About,
        city: Moscow
      }
    }, {
      path: 'Samara',
      components: {
        default: About,
        city: Samara
      }
    }, {
      path: 'Krasnoyarsk',
      components: {
        default: About,
        city: Krasnoyarsk
      }
    }, {
      path: 'Tyumen',
      components: {
        default: About,
        city: Tyumen
      }
    }, {
      path: 'Ufa',
      components: {
        default: About,
        city: Ufa
      }
    }, {
      path: 'Saratov',
      components: {
        default: About,
        city: Saratov
      }
    }, {
      path: 'Krasnodar',
      components: {
        default: About,
        city: Krasnodar
      }
    }, {
      path: 'OurValues',
      components: {
        default: About,
        city: OurValues
      }
    }, {
      path: 'History',
      components: {
        default: About,
        city: History
      }
    }, {
      path: 'Clients',
      components: {
        default: About,
        city: Clients
      }
    }, {
      path: 'Vacancies',
      components: {
        default: About,
        city: Vacancies
      }
    }]
  },  
  {
    path: '/Training',
    name: 'TrainingAbout',
    component: TrainingLayout,
    children: [{
      path: 'BasicInformation',
      components: {
        training: TrainingBasicInformation
      },
    }, {
      path: 'Structure',
      components: {
        training: TrainingStructure
      }
    }, {
      path: 'Documents',
      components: {
        training: TrainingDocuments
      }
    }, {
      path: 'Education',
      components: {
        training: TrainingEducation
      }
    }, {
      path: 'Teachers',
      components: {
        training: TrainingTeachers
      }
    }, {
      path: 'Equipments',
      components: {
        training: TrainingEquipments
      }
    }, {
      path: 'Scholarship',
      components: {
        training: TrainingScholarship
      }
    }, {
      path: 'ConsumerServices',
      components: {
        training: TrainingConsumerServices
      }
    }, {
      path: 'FinancialActivities',
      components: {
        training: TrainingFinancialActivities
      }
    }, {
      path: 'AdmissionVacancies',
      components: {
        training: TrainingAdmissionVacancies
      }
    }
    ],
  }, {
    path: '/Services',
    name: 'Services',
    component: ServicesLayout,
    children: [{
      path: 'Accounting',
      components: {
        services: ServicesAbout
      }
    }, {
      path: 'Accounting/About',
      components: {
        services: ServicesAbout
      }
    }, {
      path: 'Accounting/Nma',
      components: {
        services: ServicesAccountingNma
      }
    }
    ]
  },
  */
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
];

function addSlash(link: string): string {
  if (link.startsWith('/')) {
    return link;
  } else {
    return '/' + link;
  }
}
function removeSlash(link: string): string {
  if (link.startsWith('/')) {
    return link.substr(1, link.length - 1);
  }
  return link;
}

/*
WebSiteModule.actions.loadMenuItems().then(() => {
  // Загруженное меню
  const menuItems = WebSiteModule.state.MenuItems;

  // Меню верхнего уровня
  const topMenuItems = menuItems.filter((row) => {
    return !row.ParentId;
  });
  if (topMenuItems && topMenuItems.length > 0) {
    for (let i = 0; i < topMenuItems.length; i++) {
      const row = topMenuItems[i];

      // Топовое меню
      const topMenuLink = addSlash(row.Link);

      // Конфигурация маршрута
      const config: RouteConfig = {
        path: topMenuLink,
        component: Article,
        meta: { origin: topMenuLink, id: row.Id },
        children: []
      };

      // Обработка вложенных маршрутов
      const childMenuItems = menuItems.filter((r) => {
        return r.ParentId === row.Id;
      });
      if (childMenuItems && childMenuItems.length > 0) {
        for (let j = 0; j < childMenuItems.length; j++) {
          // Обрабатываем вложенные маршруты
          const child = childMenuItems[j];

          // Дочерний путь
          const childPath = removeSlash(child.Link);

          const childRoute: RouteConfig = {
            path: childPath,
            meta: { origin: topMenuLink, id: row.Id, articleId: child.Id },
            components: {
              articleContent: ArticleContent
            }
          };

          // добавляем в маршрут
          config.children?.push(childRoute);

          // Если есть еще маршруты
          const childSubMenuItems = menuItems.filter((r) => {
            return r.ParentId === child.Id;
          });
          if (childSubMenuItems && childSubMenuItems.length > 0) {
            for (let k = 0; k < childSubMenuItems.length; k++) {
              // Обрабатываем вложенные маршруты
              const childSub = childSubMenuItems[k];

              // Дочерний путь
              const childPath2 = removeSlash(childSub.Link);

              const childRoute2: RouteConfig = {
                path: childPath + '/' + childPath2,
                meta: { origin: topMenuLink, id: row.Id, articleId: childSub.Id },
                components: {
                  articleContent: ArticleContent
                }
              };

              // добавляем в маршрут
              config.children?.push(childRoute2);
            }

          }
        }
      }
      router.addRoute(config);
    }
  }
});
*/
WebSiteModule.actions.loadMenuItems().then(() => {
  // Загруженное меню
  const menuItems = WebSiteModule.state.MenuItems;

  if (menuItems && menuItems.length > 0) {
    for (let i = 0; i < menuItems.length; i++) {
      // Меню верхнего уровня
      const topMenu = menuItems[i];

      // Ссылка на меню верхнего уровня
      const topMenuLink = addSlash(topMenu.Link);

      // Если есть дочерние меню
      if (topMenu.SubMenu.length > 0) {
        // Конфигурация маршрута
        const config: RouteConfig = {
          path: topMenuLink,
          component: Article,
          meta: { origin: topMenuLink, id: topMenu.Id },
          children: []
        };
        // console.log('(x) route Article to [' + topMenuLink + '] with meta origin=' + topMenuLink + ', id=' + topMenu.Id);
        // Обработка вложенных маршрутов
        if (topMenu.SubMenu && topMenu.SubMenu.length > 0) {
          for (let j = 0; j < topMenu.SubMenu.length; j++) {
            // Меню дочернего уровня
            const subMenu = topMenu.SubMenu[j];

            // Ссылка на меню дочернего уровня
            const subMenuLink = removeSlash(subMenu.Link);

            // Конфигурация маршрута
            const subRoute: RouteConfig = {
              path: subMenuLink,
              meta: { origin: topMenuLink, id: subMenu.Id, articleId: subMenu.Id },
              components: {
                articleContent: ArticleContent
              }
            };

            // добавляем в маршрут
            config.children?.push(subRoute);

            // console.log('(s) subroute ArticleContent to [' + subMenuLink + '] with meta origin=' + topMenuLink + ', id=' + topMenu.Id+', articleId='+subMenu.Id);

            // Обработка вложенных маршрутов
            if (subMenu.SubMenu && subMenu.SubMenu.length > 0) {
              for (let k = 0; k < subMenu.SubMenu.length; k++) {
                // Меню дочернего уровня
                const subSubMenu = subMenu.SubMenu[k];

                // Ссылка на меню дочернего уровня
                const subSubMenuLink = removeSlash(subSubMenu.Link);

                const subSubRoute: RouteConfig = {
                  path: subMenuLink + '/' + subSubMenuLink,
                  meta: { origin: topMenuLink, id: subMenu.Id, articleId: subSubMenu.Id },
                  components: {
                    articleContent: ArticleContent
                  }
                };

                // добавляем в маршрут
                config.children?.push(subSubRoute);

                // console.log('(ss) subroute ArticleContent to [' + subMenuLink + '/' + subSubMenuLink + '] with meta origin=' + topMenuLink + ', id=' + topMenu.Id+', articleId='+subSubMenu.Id);
              }
            }
          }
        }
        router.addRoute(config);
      } else {
        // Конфигурация маршрута
        const config: RouteConfig = {
          path: topMenuLink,
          component: ArticleContent,
          meta: { origin: topMenuLink, id: topMenu.Id, articleId: topMenu.Id },
          children: []
        };
        router.addRoute(config);
        // console.log('(1) route Article to [' + topMenuLink + '] with meta origin=' + topMenuLink + ', id=' + topMenu.Id);
      }
    }
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  //console.log('before navigate to ' + to.fullPath + ' from ' + from.fullPath);
  if (to.matched.some(record => record.meta.RequireAuth)) {
    // Если для доступа к маршруту требуется аутентификация
    if (!WebSiteModule.state.Identity.IsAuthenticated) {
      // Сохраняем строку для перехода
      WebSiteModule.mutations.setReturnUrl(to.fullPath);
      // И пользователь не аутентифицирован
      next({
        path: '/Account/Login',
        params: { returnUrl: to.fullPath }
      });
      //console.log('Redirect from RequireAuth');
    } else {
      // Если требуется роль админа
      if (to.matched.some(record => record.meta.ContentManager)) {
        // Требуется роль CM
        if (WebSiteModule.state.Identity.Roles.some(record => record === 'ContentManager')) {
          next();
        }
        else {
          next({ path: '/ru/AccessDenied' });
        }
      } else {
        //next();
        if (to.matched.some(record => record.meta.Admin)) {
          // Требуется роль CM
          if (WebSiteModule.state.Identity.Roles.some(record => record === 'Admin')) {
            next();
          }
          else {
            next({ path: '/ru/AccessDenied' });
          }
        } else {
          next();
        }
      }
    }
  } else {
    next();
  }
});


export default router;
