



























































import {
    Component,
    Vue
} from 'vue-property-decorator';

import DictionaryLinks from '@/components/DictionaryLinks.vue';

import axios from 'axios';
import Dictionary from '@/models/Dictionary';
import {
    WebSiteModule
} from '@/store';

@Component({
    components: {
        DictionaryLinks
    },
})
export default class LearningTypes extends Vue {
    private url = 'LearningTypes';
    private dialog = false;
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    private items = new Array < Dictionary > ();
    private selectedItem = new Dictionary();
    private selectedRow = 0;
    private get getTitle() {
        return this.selectedItem.Id === 0 ? 'Добавление элемента меню' : 'Редактирование элемента меню';
    }

    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);
            await this.load();
        });
    }
    private async load() {
        this.loading = true;
        try {
            const response = await axios.get(this.url);
            this.items = response.data;
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error;
            return;
        }
    }
    private addItem() {      
        this.selectedItem = new Dictionary();  
        this.dialog = true;
    }
    private editItem(row: Dictionary, index: number) {
        this.selectedRow = index;
        this.selectedItem = new Dictionary(row);
        this.dialog = true;
    }
    private async deleteItem(row: Dictionary, index: number) {
        this.selectedItem = row;
        this.loading = true;
        try {
            await axios.delete(this.url +'/'+this.selectedItem.Id);
            this.items.splice(index, 1);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private async saveItem() {
        if (this.selectedItem.Name.length < 3) {
            this.error('Не заполнено название');
            return;
        }

        var row = new Dictionary(this.selectedItem);

        this.loading = true;
        try {
            if (this.selectedItem.Id === 0) {
                // Добавление
                const response = await axios.post(this.url, row);
                this.items.push(response.data);
            } else {
                // Редактирование
                //console.log(row);
                await axios.put(this.url + '/'+this.selectedItem.Id, row);
                // Обновляем в таблице
                Vue.set(this.items, this.selectedRow, row);
            }
            this.loading = false;
            this.dialog = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
}
