























import {
    Component,
    Vue
} from 'vue-property-decorator';
// import $ from 'jquery';
// import { IModalForm } from '@/model/ModalForm';

@Component({})
export default class ModalForm extends Vue {
    private title = 'Веб-сайт ООО "РН-Учет"';
    private text = '';

    public messageBox(text: string, title ? : string): void {
        if (title) {
            this.title = title;
        } else {
            this.title = 'Веб-сайт ООО "РН-Учет"';
        }

        this.text = text;
        //$('#modalForm').modal('show');
    }

    public onErrorResponse(error: any): void {
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.exceptionMessage) {
                    this.messageBox(
                        '<b>' +
                        error.response.data.message +
                        '</b><br/>' +
                        error.response.data.exceptionMessage
                    );
                    return;
                }

                if (error.response.data.message) {
                    this.messageBox(error.response.data.message);
                    return;
                }

                if (error.response.data.Message) {
                    this.messageBox(error.response.data.Message);
                    return;
                }

                this.messageBox(error.response.data);
                return;
            }
        }

        if (error.error) {
            if (error.error.innererror) {
                if (error.error.innererror.internalexception) {
                    if (error.error.innererror.internalexception.message) {
                        this.messageBox(error.error.innererror.internalexception.message);
                        return;
                    } else {
                        this.messageBox(error.error.innererror.message);
                        return;
                    }
                } else {
                    this.messageBox(error.error.innererror.message);
                    return;
                }
            } else {
                this.messageBox(error.error.message);
                return;
            }
        }

        if (error.Message) {
            this.messageBox(error.Message);
            return;
        }

        if (error.message) {
            if (error.message.indexOf('404') > 0) {
                this.messageBox('Статья не найдена');
            } else {
                this.messageBox(error.message);
            }
        }
    }
}
