






















import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component({})
export default class DialogWindow extends Vue {
    @Prop({
        type: String,
        required: false,
        default: 'Портал ООО "РН-Учет"'
    })
    private title!: string;
    private get getTitle() {
        return this.title;
    }
    @Prop()
    private text!: string;
    private get getText() {
        return this.text;
    }

    @Prop({
        type: Boolean,
        required: true,
        default: false
    })
    private dialog!: boolean;
    private get _dialog() {
        return this.dialog;
    }
    private set _dialog(modal: boolean) {
        this.$emit('update:dialog', modal);
    }
}
