export default class LearningCourseScheduleResponse {
    public Id = 0;
    public LearningCourseId = 0;    
    public LearningCourseName = '';
    public LearningProgrammId = 0;
    public LearningProgrammName = '';
    public LearningTypeId = 0;
    public LearningTypeName = '';
    public DateFrom = new Date();
    public DateTill: Date | null = null;
    public Status = '';
    public Price = 0;
    public Enabled = false;
    public Visible = false;

    constructor(row : LearningCourseScheduleResponse | null = null) {
        if (!row) {
            return;
        }
        this.Id = row.Id;
        this.Price = row.Price;
        this.LearningCourseId = row.LearningCourseId;
        this.LearningCourseName = row.LearningCourseName;
        this.LearningProgrammId = row.LearningProgrammId;
        this.LearningProgrammName = row.LearningProgrammName;
        this.LearningTypeId = row.LearningTypeId;
        this.LearningTypeName = row.LearningTypeName;
        this.DateFrom = new Date(row.DateFrom);
        if (row.DateTill) {
            this.DateTill = new Date(row.DateTill);
        }
        this.Status = row.Status;
        this.Enabled = row.Enabled;                
        this.Visible = row.Visible;                
    }
}