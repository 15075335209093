






























































import {
    Component,
    Vue
} from 'vue-property-decorator';

import {
    WebSiteModule
} from '@/store';

import axios from 'axios';
import Article from '@/models/Article';
import HtmlMarkup from '@/models/HtmlMarkup';
import ArticleFile from '@/models/ArticleFile';

@Component({
    components: {},
})
export default class ArticleHtmlEdit extends Vue {
    private uri = 'Articles';
    private uriFile = 'ArticleFiles';
    private id = 0;
    private html = '';
    private htmlVisible = false;
    private article = new Article();
    private articleFiles = new Array < ArticleFile > ();
    private file: File | null = null;
    private files = new Array < File > ();
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    private fileSize(file: ArticleFile) {
        if (file.Width > 0 && file.Height > 0) {
            return file.Width + '*' + file.Height;
        }
        return '';
    }
    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);

            if (!this.$route.query.MenuId) {
                return;
            }

            this.id = Number(this.$route.query.MenuId);
        });
    }
    private async addFile() {
        if (this.file) {
            const data = new FormData();
            data.append('ArticleId', String(this.article.Id));
            data.append('File', this.file, this.file.name);
            this.loading = true;
            try {
                const response = await axios.post(this.uriFile, data);
                this.article.ArticleFiles.push(new ArticleFile(response.data));
                this.loading = false;
                this.file = null;
            } catch (error) {
                this.loading = false;
                this.error(error);
            }
        }
    }
    private async deleteFile(index: number) {
        const file = this.article.ArticleFiles[index];
        this.loading = true;
        try {
            await axios.delete(this.uriFile + '/' + file.Id);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
        }
        this.html = HtmlMarkup.removeFileName(file.FileName, this.html);
        this.article.ArticleFiles.splice(index, 1);
    }
    private insertFile(file: ArticleFile) {
        // Имя файла без пробелов
        const fileName = HtmlMarkup.getFileNameTag(HtmlMarkup.removeSpaces(file.FileName));
        this.article.Text += fileName;
        // обновить просмотр
        this.rebuildPreview();
    }
    private togglePreview() {
        if (!this.htmlVisible) {
            this.rebuildPreview();
        }
        this.htmlVisible = !this.htmlVisible;
    }
    private rebuildPreview() {
        this.html = HtmlMarkup.getMarkupArticle(this.article, axios.defaults.baseURL);
    }
    private async save() {
        if (this.article.Id === 0) {
            await this.saveAsNew();
        } else {
            await this.saveAsEdited();
        }
    }
    private async saveAsNew() {
        const row = new Article();
        row.Id = this.article.Id;
        row.Subject = this.article.Subject;
        row.Text = this.article.Text;
        row.Visible = this.article.Visible;
        this.loading = true;

        const data = new FormData();
        data.append('MenuItemId', String(this.id));
        data.append('Subject', this.article.Subject);
        data.append('Text', this.article.Text);
        data.append('Visible', String(this.article.Visible));
        this.files.forEach(file => {
            data.append('Files', file, file.name);
        });

        try {
            const response = await axios.post(this.uri, data);
            this.article = new Article(response.data);
            this.loading = false;
            this.error('Сохранено');
        } catch (error) {
            this.loading = false;
            this.error(error);
        }
    }
    private async saveAsEdited() {
        const row = new Article();
        row.Id = this.article.Id;
        row.Subject = this.article.Subject;
        row.Text = this.article.Text;
        row.Visible = this.article.Visible;
        this.loading = true;
        try {
            await axios.patch(this.uri + '/' + this.article.Id, row);
            this.loading = false;
            this.error('Сохранено');
        } catch (error) {
            this.loading = false;
            this.error(error);
        }
    }
    private async deleteArticle() {
        this.loading = true;
        try {
            await axios.delete(this.uri + '/' + this.article.Id);
            this.loading = false;
            this.error('Статья удалена');
            setTimeout(() => {
                this.$router.push('/');
            }, 1000);
        } catch (error) {
            this.loading = false;
            this.error(error);
        }
    }
}
