











import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component({
    components: {

    },
})
export default class DatePicker extends Vue {
    private menu = false;
    @Prop({
        type: Date,
    })
    private value!: Date;
    private get date() {
        return this.value;
    }
    private set date(date: Date) {
        this.$emit('input', date);
    }
    private get isoDate(){
        if (!this.value) {
            return this.value;            
        }
        return this.value.toISOString().substr(0, 10);
    }
    private set isoDate(date: string) {
        this.$emit('input', new Date(date));
    }
    @Prop()
    private label!: string;
    private get title() {
        return this.label;
    }
    private get formattedDate() {
        if (!this.value) {
            return '';
        }
        let month = String(this.value.getMonth() + 1);
        let day = String(this.value.getDate());
        const year = String(this.value.getFullYear());

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return day + '.' + month + '.' + year;
    }
}
