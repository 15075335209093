import { Mutations } from 'vuex-smart-module';
import WebSiteState from './state';
import Menu from '@/models/Menu';
import Identity from '@/models/Identity';

export default class WebSiteMutations extends Mutations<WebSiteState> {
    logout():void {
        this.state.Identity = new Identity();
    }
    login(data: Identity): void {
        this.state.Identity = new Identity(data);
        if (this.state.Identity.Roles && this.state.Identity.Roles.length > 0) {
            const admin = this.state.Identity.Roles.some((row) => {
                return row === 'Admin';
            });
            const cm = this.state.Identity.Roles.some((row) => {
                return row === 'ContentManager';
            });
            this.state.CanAdd = admin || cm;
            this.state.CanEdit = admin || cm;
            this.state.CanDelete = admin || cm;
        } else {
            this.state.CanAdd = false;
            this.state.CanEdit = false;
            this.state.CanDelete = false;
        }
    }
    setReturnUrl(data: string): void {
        this.state.ReturnUrl = data;
    }
    setMenuItems(data: Menu[]): void {
        const nest = (items: Menu[], id: number | null = null): Menu[] =>  items
        .filter(item => item.ParentId === id)
        .map(item => ({ ...item, SubMenu: nest(items, item.Id) }));

        this.state.PlainMenuItems = data;
        this.state.MenuItems = nest(data);
        //console.log(nest(data));       
    }
    setArticleId(id: number): void {
        this.state.ArticleId = id;
    }
    setSubject(subject: string): void {
        this.state.Subject = subject;
    }
    setHtml(html: string): void {
        this.state.Html = html;
    }
    setVisible(visible: boolean): void {
        this.state.Visible = visible;
    }
    setContinue(c: boolean): void {
        this.state.Continue = c;
    }
    setFiles(files: File[]): void {
        this.state.Files = files;
    }
    setLoading(c: boolean): void {
        this.state.Loading = c;
    }
    setInitializing(c: boolean): void {
        this.state.Initializing = c;
    }
    setError(error: any):void {
        //console.log(error);
        //console.log(error.data);
        if (typeof error == 'object') {
            // Если http ошибка
            if (error.response?.data) {
                this.state.Error = error.response?.data;
            } else if (error.message) {
                this.state.Error = error.message;
            } else {
                this.state.Error = error;
            }
        } else {
            this.state.Error = error;
        }
        this.state.Dialog = true;
    }    
    setDialog(dialog: boolean):void {
        this.state.Dialog = dialog;
    }
    appendFile(file: File): void {
        this.state.Files.push(file);
    }
    deleteFile(index: number): void {
        this.state.Files.splice(index, 1);
    }
}