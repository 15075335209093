import { Module } from 'vuex-smart-module';
import getters from './getters';
import state from './state';
import mutations from './mutations';
import actions from './actions';

const WebSiteModule = new Module({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
});

export default WebSiteModule;