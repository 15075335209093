









import {
    Component,
    Vue
} from 'vue-property-decorator';

import {
    WebSiteModule
} from '@/store';

@Component({
    components: {

    },
})
export default class AccessDenied extends Vue {
    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);
        });
    }
}

