import Menu from '@/models/Menu';
import Identity from '@/models/Identity';

export default class WebSiteState {
    public Identity = new Identity();
    public ReturnUrl = '';
    public Initializing = true;
    public Loading = false;
    public MenuItems = new Array<Menu>();
    public PlainMenuItems = new Array<Menu>();
    public ArticleId = 0;
    public Subject = '';
    public Html = '';
    public Visible = false;
    public Continue = false;
    public Files = new Array<File>();
    public Dialog = false;
    public Error = '';
    public CanAdd = false;
    public CanEdit = false;
    public CanDelete = false;
}
