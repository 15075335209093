





















import {
    Component,
    Vue
} from 'vue-property-decorator';

import {
    WebSiteModule
} from '@/store';
import MenuItem from '@/models/MenuItem';
import ModalForm from '@/components/ModalForm.vue';

import axios from 'axios';

@Component({
    components: {
        ModalForm,
    },
})
export default class ArticleContent extends Vue {
    private articleUri = 'Article';
    private menuItems = new Array < MenuItem > ();
    //private permission = new Permission();
    private subject = '';
    private html = '';
    private loaded = false;

    private get menuItemsExist() {
        return this.menuItems.length > 0;
    }
    private get allMenuItems() {
        return WebSiteModule.state.MenuItems;
    }
    private get canAdd() {
        return !this.loaded && WebSiteModule.state.CanAdd;
    }
    private get canEdit() {
        return this.loaded && WebSiteModule.state.CanEdit;
    }
    private get canDelete() {
        return this.loaded && WebSiteModule.state.CanDelete;
    }
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);
            await this.load();
        });
    }
    private async load() {
        const articleId = Number(this.$route.meta.articleId);
        this.loading = true;
        try {
            const response = await axios.get(this.articleUri + '/' + articleId);
            this.subject = response.data.Subject;
            this.html = response.data.Content;
            this.loading = false;
            this.loaded = true;
        } catch (error) {
            this.loading = false;
            this.error(error);            
            return;
        }
    }
    private gotoAdd() {
        const articleId = Number(this.$route.meta.articleId);
        this.$router.push('/ru/ArticleHtmlAdd?MenuId=' + articleId);
    }
    private gotoEdit() {
        const articleId = Number(this.$route.meta.articleId);
        this.$router.push('/ru/ArticleHtmlEdit?MenuId=' + articleId);
    }
}
