import FileBlob from '@/models/FileBlob';

export default class ArticleFile {
    public Id = 0;
    public ArticleId = 0;
    public Caption = '';    
    public FileName = '';
    public FileType = '';
    public Hash = '';
    public Link = '';
    public FileSize = 0;
    public Width = 0;
    public Height = 0;
    public FileBlob: FileBlob | null = null;

    constructor(row: ArticleFile | null = null) {
        if (!row){
            return;
        }
        this.Id = row.Id;
        this.ArticleId = row.ArticleId;
        this.Caption = row.Caption;
        this.FileName = row.FileName;
        this.FileType = row.FileType;
        this.Hash = row.Hash;
        this.Link = row.Link;
        this.FileSize = row.FileSize;
        this.Width = row.Width;
        this.Height = row.Height;

        if (row.FileBlob) {
            this.FileBlob = new FileBlob(row.FileBlob);
        }
    }
}