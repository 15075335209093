











































































import {
    Component,
    Vue
} from 'vue-property-decorator';

import axios from 'axios';
import Dictionary from '@/models/Dictionary';
import LearningCourse from '@/models/LearningCourse';
import DatePicker from '@/components/DatePicker.vue';
import DictionaryLinks from '@/components/DictionaryLinks.vue';

import {    
    WebSiteModule
} from '@/store';

@Component({
    components: {
        DatePicker,
        DictionaryLinks
    },
})
export default class LearningCourses extends Vue {
    private url = 'LearningCourses';
    private urlLearningProgramms = 'LearningProgramms';
    private dialog = false;
    private get loading() {
        return WebSiteModule.state.Loading;
    }
    private set loading(v: boolean) {
        WebSiteModule.mutations.setLoading(v);
    }
    private error(e: any) {
        WebSiteModule.mutations.setError(e);
    }
    private items = new Array < LearningCourse > ();
    private learningProgramms = new Array<Dictionary>();
    private selectedItem = new LearningCourse();
    private selectedLp = new Dictionary();
    private selectedRow = 0;
    private get getTitle() {
        return this.selectedItem.Id === 0 ? 'Добавление элемента меню' : 'Редактирование элемента меню';
    }

    public mounted(): void {
        this.$nextTick(async () => {
            // Сохраняем навигацию
            await WebSiteModule.actions.navigate(this.$route.fullPath);
            await this.load();
        });
    }
    private async load() {
        this.loading = true;
        try {
            const response2 = await axios.get(this.urlLearningProgramms);
            this.learningProgramms = response2.data;
            const response1 = await axios.get(this.url);
            this.items = response1.data;            
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error;
            return;
        }
    }
    private addItem() {
        this.selectedItem = new LearningCourse();  
        this.dialog = true;
    }
    private editItem(row: LearningCourse, index: number) {        
        this.selectedRow = index;
        this.selectedItem = new LearningCourse(row);
        this.selectedLp = this.learningProgramms.filter((r) => { return r.Id === row.LearningProgrammId; })[0];
        this.dialog = true;
    }
    private async deleteItem(row: LearningCourse, index: number) {
        this.selectedItem = row;
        this.loading = true;
        try {
            await axios.delete(this.url +'/'+this.selectedItem.Id);
            this.items.splice(index, 1);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private async saveItem() {
        if (this.selectedItem.Name.length < 3) {
            this.error('Не заполнено название');
            return;
        }
        if (this.selectedItem.Text.length < 3) {
            this.error('Не заполнено описание');
            return;
        }
        
        if (!this.selectedLp || this.selectedLp.Id < 1) {
            this.error('Не заполнено программа обучения');
            return;
        }
        var row = new LearningCourse(this.selectedItem);
        row.LearningProgramm = null;
        row.LearningProgrammId = this.selectedLp.Id;
        this.loading = true;
        try {
            if (this.selectedItem.Id === 0) {
                // Добавление
                const response = await axios.post(this.url, row);
                this.items.push(response.data);
            } else {
                // Редактирование
                //console.log(row);
                await axios.patch(this.url, row);
                // Обновляем в таблице
                Vue.set(this.items, this.selectedRow, row);
            }
            this.loading = false;
            this.dialog = false;
        } catch (error) {
            this.loading = false;
            this.error(error);
            return;
        }
    }
    private getLearningProgramm(row: LearningCourse) {
        const find = this.learningProgramms.filter((lp)=> {
            return lp.Id === row.LearningProgrammId;
        });
        if (find && find.length > 0) {
            return find[0].Name;
        }
        return '';
    }
    private goBack() {
        this.$router.go(-1);
    }
    private formattedDate(date: Date | string | null) {
        if (!date || date === '') {
            return '';
        }

        const d = new Date(date);

        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return day + '.' + month + '.' + year;
    }
    private formattedDateFull(date: Date | string) {
        if (!date || date === '') {
            return '';
        }

        const d = new Date(date);

        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());

        let hour = String(d.toLocaleTimeString());
        hour = hour.substring(0, hour.lastIndexOf(':'));

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return day + '.' + month + '.' + year + ' ' + hour;
    }
    private getDateRange(row: LearningCourse) {
        let text = 'с '+this.formattedDate(row.DateFrom);
        if (row.DateTill) {
            text+= ' до ' +this.formattedDate(row.DateTill);
        }
        return text;
    }
}
