




import {
    Component,
    Vue
} from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class Redirect extends Vue {
    public mounted(): void {
        this.$nextTick(async () => {
            const path = this.$route.params.id;
            if (path && path.length > 0) {
                this.$router.push('/'+path);
            }
        });
    }
}
